<template>
    <div id="content-block">
        <div class="page-height" :style="{ 'height': `${window.height}px`, 'min-height': `${window.height}px`}">

            <div class="homepage-7-container">

                <div class="homepage-7-slider mt-lg-3 mt-md-5 mt-xl-0">

                    <div class="swiper-button-prev swiper-button d-none d-md-block style-2 dark"
                         slot="button-prev"></div>
                    <div class="swiper-button-next swiper-button d-none d-md-block style-2 dark"
                         slot="button-next"></div>

                    <swiper class="swiper home-slider" data-mousewheel="1" data-parallax="1"
                            :options="swiperOption">

                        <swiper-slide>
                            <div class="slide-text-label left d-none d-md-block">
                                <div class="rotate max-content">Music Producer.</div>
                            </div>

                            <div class="animated-frame-entry">
                                <div class="align">
                                    <div class="layer-entry">
                                        <div class="align">
                                            <div class="animated-frame">
                                                <div class="title">
                                                    <div class="h2" data-swiper-parallax="-600"><b><i>#TSV</i></b></div>
                                                    <div class="empty-space mb-3"></div>
                                                    <!--                                                    <a class="button-link" href="#">read more</a>-->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="layer-entry bg-3">
                                        <div class="align">
                                            <div class="animated-frame">
                                                <div class="title">
                                                    <div class="h2 light" data-swiper-parallax="-600"><b><i>#TSV</i></b></div>
                                                    <div class="empty-space col-xs-b15"></div>
                                                    <!--                                                    <a class="button-link light" href="#">read more</a>-->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="slide-text-label right d-none d-md-block">
                                <div class="rotate max-content">Composer. Performer.</div>
                            </div>
                        </swiper-slide>

                        <swiper-slide>
                            <div class="slide-text-label left d-none d-md-block">
                                <div class="rotate max-content">Music Producer.</div>
                            </div>

                            <div class="animated-frame-entry">
                                <div class="align">
                                    <div class="layer-entry">
                                        <div class="align">
                                            <div class="animated-frame">
                                                <div class="title">
                                                    <div class="h2" data-swiper-parallax="-600"><b><i>#TSV</i></b></div>
                                                    <div class="empty-space mb-3"></div>
                                                    <!--                                                    <a class="button-link" href="#">read more</a>-->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="layer-entry bg-2">
                                        <div class="align">
                                            <div class="animated-frame">
                                                <div class="title">
                                                    <div class="h2 light" data-swiper-parallax="-600"><b><i>#TSV</i></b></div>
                                                    <div class="empty-space mb-3"></div>
                                                    <!--                                                    <a class="button-link light" href="#">read more</a>-->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="slide-text-label right d-none d-md-block">
                                <div class="rotate max-content">Composer. Performer.</div>
                            </div>

                        </swiper-slide>

                        <div class="swiper-pagination swiper-pagination-white d-lg-none" slot="pagination"></div>

                    </swiper>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
import 'swiper/css/swiper.css';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

export default {
    metaInfo: {
        title: 'Home'
    },
    name: 'Home',
    components: {
        Swiper,
        SwiperSlide
    },
    data () {
        return {
            window: {
                height: window.innerHeight
            },
            swiperOption: {
                direction: 'horizontal',
                slidesPerView: 1,
                spaceBetween: 0,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                mousewheel: true,
                parallax: true,
                centeredSlides: true,
                // autoplay: true,
                autoplay: {
                    delay: 8000,
                    disableOnInteraction: false
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                breakpoints: {
                    1200: {
                        speed: 1500
                    },
                    1024: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                        speed: 1000
                    },
                    768: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                        speed: 800
                    },
                    640: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                        speed: 800
                    },
                    320: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                        speed: 800
                    }
                }
            }
        };
    }
};
</script>
