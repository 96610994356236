<template>
    <div id="content-block">

        <div class="wide-container">
            <div class="header-empty-space"></div>
            <div class="empty-space col-b55 col-sm-b110"></div>

            <div class="row">
                <div class="col-lg-6 col-text-center col-lg-text-left">
                    <div class="h2 small"><b>OUR PORTFOLIO</b></div>
                </div>
            </div>

            <div class="empty-space col-b30 col-sm-b60"></div>

            <div class="sorting-container portfolio-6 row m-xs-0">

                <CoolLightBox
                    :items="items"
                    :index="index"
                    @close="index = null">
                </CoolLightBox>

                <div class="sorting-item col-12 col-md-6 col-lg-4" v-for="(image, imageIndex) in items"
                     :key="imageIndex">
                    <div class="portfolio-landing-entry-1 style-1">
                        <a class="mouseover poster-3d lightbox portfolio-bg-1" @click="index = imageIndex"
                           :style="{ backgroundImage: 'url(' + image + ')' }">
                            <span class="layer-1 full-size">
                                <span class="mouseover-helper-frame"></span>
                            </span>
                            <span class="layer-1 full-size">
                                <span class="mouseover-helper-icon"></span>
                            </span>
                        </a>
                        <div class="text-content">
                            <div class="row">
                                <div class="col-lg-6 col-text-center col-lg-text-left">
                                    <div class="h5"><b><a href="#" class="mouseover-simple size-1">01. FIORITURA</a></b>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-text-center col-lg-text-right">
                                    <div class="simple-article grey small">branding / photoshooting</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="empty-space col-b15"></div>

    </div>
</template>

<script>

import img1 from '../assets/image/banner/1.jpg';
import img2 from '../assets/image/banner/2.jpg';
import img3 from '../assets/image/banner/3.jpg';
import img4 from '../assets/image/banner/4.jpg';
import img5 from '../assets/image/banner/5.jpg';

export default {
    name: 'portfolio',
    metaInfo: {
        title: 'Portfolio'
    },
    data: function () {
        return {
            items: [
                img1, img2, img3, img4, img5
            ],
            index: null
        };
    }
};

</script>

<style scoped>

</style>
