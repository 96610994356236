<template>
    <div id="content-block">
         <div class="position-relative">
            <div class="banner-slider">
                <swiper class="swiper" :options="swiperOption1">
                    <swiper-slide>
                        <div class="bg-5 background-slider"
                             :style="{ 'height': `${window.height}px`, 'min-height': `${window.height}px`}"></div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="bg-7 background-slider"
                             :style="{ 'height': `${window.height}px`, 'min-height': `${window.height}px`}"></div>
                    </swiper-slide>

                </swiper>
            </div>
            <div class="page-height" :style="{ 'height': `${window.height}px`, 'min-height': `${window.height}px`}">
                <div class="full-size-banner-entry full-size valign-middle">
                    <div class="valign-text-wrapper text-center">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="h2 light"><b>WHAT I DELIVER</b></div>
                                <div class="empty-space col-b15 d-none"></div>
                                <div class="banner-max-width d-none">
                                    <div class="simple-article large light transparent">Lorem ipsum dolor sit amet,
                                        consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                                        dolore magna aliqua
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-scroll-to="'#element'" class="scroll-button style-3 light d-none d-md-block"></div>
            </div>
        </div>

        <div class="container" id="element">
            <div class="empty-space col-b55 col-md-b110"></div>

            <div class="row col-text-center col-md-text-left justify-content-md-center d-none">
                <div class="col-md-5">
                    <div class="h3"><b>WE CAN PROPOSE OUR SPESIAL SEVICES</b></div>
                    <div class="empty-space col-b10 col-md-b0"></div>
                </div>
                <div class="col-md-5">
                    <div class="simple-article">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras elementum id metus ac tempus.
                            Praesent ut mauris eget velit volutpat posuere nec ut elit. Etiam efficitur iaculis nulla a
                            commodo. Sed eget mi sed tortor semper volutpat. In vehicula tristique rutrum. Etiam sit
                            amet justo et enim vulputate sagittis</p>
                    </div>
                </div>
            </div>

            <div class="empty-space col-b55 col-md-b110 d-none"></div>

            <div class="row">
                <div class="col-md-12">
                    <div class="left-right-entry style-1 new-view clearfix">
                        <div class="right">
                            <div class="entry full-size section-bg-1"></div>
                        </div>
                        <div class="left">
                            <div class="row justify-content-md-center">
                                <div class="col-md-10">
                                    <div class="cell-view">
                                        <div class="simple-article border-left">
                                            <h3><b>Ad Jingle Production & Music Short’s</b></h3>
                                            <div class="empty-space col-b15"></div>
                                            <p>If you are looking to get your brands more engaging with your consumers, chances are you might
                                            need the backing of a catchy advertising jingle in your promotion. One that keeps your consumers
                                            hooked and your brand right at the top of their minds. Right from automobiles to textiles, education
                                            to home furnishing we have been part of a wide array of brand verticals and campaign over these
                                            years. </p>
                                            <p>Music Short’s is all about composing for aspiring short filmmakers. While the process is no less than
                                            laying your hands on a fully-fledged feature film, Music Short’s is an equally exciting vertical in our
                                            offering. We have been fortunate to work with talent-packed filmmakers who eventually have risen
                                            to fame with their maiden venture. Right from horror, to romance we have got them all ticked.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="empty-space col-b30 col-md-b60"></div>

            <div class="row">
                <div class="col-lg-12">
                    <div class="left-right-entry style-1 clearfix">
                        <div class="right">
                            <div class="entry full-size section-bg-2"></div>
                        </div>
                        <div class="left">
                            <div class="row">
                                <div class="col-md-10">
                                    <div class="cell-view">
                                        <div class="simple-article border-left">
                                            <h3><b>Original Song Production </b></h3>
                                            <div class="empty-space col-b15"></div>
                                            <p>Be it for films, short films, special projects, or even social initiatives there is nothing like working on a
                                            brand new concept from the scratch. 'Original Song Production' is all about brought on board a
                                            never before musical experience to this world. The journey from cracking the tune to setting up the
                                            arrangement, layering up the right vocals to final mixing we have what it takes to stay 'Original' in
                                            the world of music. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="empty-space col-b30 col-md-b60"></div>

            <div class="row">
                <div class="col-lg-12">
                    <div class="left-right-entry style-1 new-view clearfix">
                        <div class="right">
                            <div class="entry full-size section-bg-3"></div>
                        </div>
                        <div class="left">
                            <div class="row justify-content-md-center">
                                <div class="col-md-10">
                                    <div class="cell-view">
                                        <div class="simple-article border-left">
                                            <h3><b>Original Score Production</b></h3>
                                            <div class="empty-space col-b15"></div>
                                            <p>We would love to back you unconditionally. With the music of course. While it might just seem
                                            that a background score for a film is not intended to be the primary focus of the viewer but it
                                            certainly plays a vital role in the narrative. With 'Original Score Production' we deliver
                                            background scores for the film as well as non-film projects. Across genres and languages. Get in
                                            touch with us to see our latest portfolio and associations to know why we could be your best
                                            choice for your dream project</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="empty-space col-b30 col-md-b60 d-none"></div>

            <div class="row d-none">
                <div class="col-lg-12">
                    <div class="left-right-entry style-1 clearfix">
                        <div class="left">
                            <div class="row">
                                <div class="col-md-10">
                                    <div class="cell-view">
                                        <div class="simple-article border-left">
                                            <h3><b>ANIMATION AND VIDEO</b></h3>
                                            <div class="empty-space col-b15"></div>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras elementum
                                                id metus ac tempus. Praesent ut mauris eget velit volutpat posuere nec
                                                ut elit. Etiam efficitur iaculis nulla a commodo. Sed eget mi sed tortor
                                                semper volutpat. In vehicula tristique rutrum. Etiam sit amet justo et
                                                enim vulputate sagittis</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="right">
                            <div class="entry full-size section-bg-4"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="empty-space col-b55 col-md-b110 d-none"></div>

            <div class="row d-none">
                <div class="col-md-12">
                    <div class="left-right-entry style-1 new-view clearfix">
                        <div class="right">
                            <div class="entry full-size section-bg-5"></div>
                        </div>
                        <div class="left">
                            <div class="row justify-content-md-center">
                                <div class="col-md-10">
                                    <div class="cell-view">
                                        <div class="simple-article border-left">
                                            <h3><b>CORPORATE IDENTITY</b></h3>
                                            <div class="empty-space col-b15"></div>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras elementum
                                                id metus ac tempus. Praesent ut mauris eget velit volutpat posuere nec
                                                ut elit. Etiam efficitur iaculis nulla a commodo. Sed eget mi sed tortor
                                                semper volutpat. In vehicula tristique rutrum. Etiam sit amet justo et
                                                enim vulputate sagittis</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="empty-space col-b30 col-md-b60"></div>

            <div class="row">
                <div class="col-lg-12">
                    <div class="left-right-entry style-1 clearfix">
                        <div class="right">
                            <div class="entry full-size section-bg-6"></div>
                        </div>
                        <div class="left">
                            <div class="row">
                                <div class="col-md-10">
                                    <div class="cell-view">
                                        <div class="simple-article border-left">
                                            <h3><b>Live Shows </b></h3>
                                            <div class="empty-space col-b15"></div>
                                            <p>TSV is also a performing artist and hosts live shows. EDM being his core strength do get in touch to
                                            turn your events supremely exciting and entertaining. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="empty-space col-b30 col-md-b60"></div>

        </div>

        <!-- FOOTER -->
        <div class="grey-line"></div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';

export default {
    metaInfo: {
        title: 'What We Offer'
    },
    name: 'Offer',
    components: {
        Swiper,
        SwiperSlide
    },
    data () {
        return {
            window: {
                height: window.innerHeight
            },
            swiperOption1: {
                centeredSlides: true,
                effect: 'fade',
                fadeEffect: { crossFade: true },
                speed: 3000,
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false
                },
                slidesPerView: 1,
                spaceBetween: 0,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                loop: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                breakpoints: {
                    1024: {
                        slidesPerView: 1,
                        spaceBetween: 0
                    },
                    768: {
                        slidesPerView: 1,
                        spaceBetween: 0
                    },
                    640: {
                        slidesPerView: 1,
                        spaceBetween: 0
                    },
                    320: {
                        slidesPerView: 1,
                        spaceBetween: 0
                    }
                }
            }
        };
    }
};
</script>

<style scoped>

</style>
