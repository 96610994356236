<template>
    <div id="content-block font-8">
        <div class="position-relative">
            <div class="banner-slider">
                <swiper class="swiper" :options="swiperOption1">
                    <swiper-slide>
                        <div class="bg-4 background-slider"
                             :style="{ 'height': `${window.height}px`, 'min-height': `${window.height}px`}"></div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="bg-6 background-slider"
                             :style="{ 'height': `${window.height}px`, 'min-height': `${window.height}px`}"></div>
                    </swiper-slide>

                </swiper>
            </div>
            <div class="page-height" :style="{ 'height': `${window.height}px`, 'min-height': `${window.height}px`}">
                <div class="full-size-banner-entry full-size valign-middle">
                    <div class="valign-text-wrapper text-center">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="quotes"><b>“I want to go and write music that announces to you that you can feel something. I don’t want to tell you what to feel, but I just want you to have the possibility of feeling something.”</b>
                                <br/>
                                <br/>
                                — Hans Zimmer
                                </div>
                               <!--  <div class="empty-space col-xs-b15"></div>
                                <div class="banner-max-width">
                                    <div class="simple-article large light transparent">Lorem ipsum dolor sit amet,
                                        consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                                        dolore magna aliqua.
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div v-scroll-to="'#element'" class="scroll-button style-3 light hidden-xs"></div>
            </div>
        </div>

        <div class="container" id="element">
            <div class="empty-space col-b55 col-md-b110"></div>

            <!--            <div class="row justify-content-center">-->
            <!--                <div class="col-md-6 text-center">-->
            <!--                    <div class="h2 small"><b>HARD WORKERS TEAM</b></div>-->
            <!--                    <div class="empty-space col-b15"></div>-->
            <!--                    <div class="simple-article large grey">Lorem ipsum dolor sit amet, consectetur adipiscing elit.-->
            <!--                        Phasellus sed eLorem ipsum dolor sit amet, consectetur adipiscing elit-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--            </div>-->

            <div class="empty-space col-b30 col-md-b20"></div>

            <div class="row col-text-center col-md-text-left justify-content-md-center">
                <div class="col-md-9">
                    <div class="h4 mb-4 text-uppercase"><b>Music Producer. Composer. Performer.</b></div>
                    <div class="empty-space col-b10 col-md-b0"></div>
                </div>
            </div>
            <div class="row col-text-center col-md-text-left justify-content-md-center">
                <div class="col-md-9">
                    <div class="simple-article">
                        <p>T S Vishnu aka TSV was born in a humble south Indian family who were loyal fans of Indian film music. Education seemed to be the top priority for everyone around and Vishnu as a kid was guided towards academics until he turned 13. As a stroke of luck or call it destiny, one day little Vishnu composed a tune out of now where and shaped it to perfection without any ones help. It changed his life completely. His newfound passion for music persuaded him to learn the craft professionally. And he just did that. He began learning piano soon and mastered western orchestration, arrangement, and music production as years went on. With growing age, his passion for music grew stronger and stronger. </p>
                    </div>
                </div>
            </div>

            <div class="empty-space col-b25 col-md-b50"></div>
            <div class="row col-text-center col-md-text-left justify-content-md-center">
                <div class="col-md-9">
                    <div class="row col-text-center col-md-text-left justify-content-md-center">
                        <div class="col-md-6">
                            <div class="simple-article">
                                <p>Meanwhile, TSV began his professional career with the world’s leading multinational company and today has over a decade long of experience in the IT sector.</p>
                                <p>He began his musical career by composing music albums in the year 2007. He was soon approached by an array of filmmakers for composing original soundtracks for their projects. Background scoring being his forte, TSV has been part of over 50 short films and numerous ad films of brands across India. He has also worked on multi-lingual projects including Kannada, Tamil and Telugu so far.</p>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="simple-article">
                                <p>T S Vishnu marked his foray into Mollywood with Anoop Menon starrer ‘Malgudi Days’ in the year 2016. A project that won him accolades from critics and media with equal fervor. TSV owns his studio in the capital city of Kerala, Thiruvanthapuram, and is actively engaged with leading media channels including Amrita Television.</p>
                                <p>TSV is also trained in western orchestration and currently collaborates with several overseas artists and production houses.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="empty-space col-b55 col-md-b110"></div>

        </div>
        <div class="clearfix">
            <a class="thumbnail-line-entry overlay-thumbnail">
                <img src="../assets/image/thumbnail/1.jpg" alt=""/>
                <img src="../assets/image/thumbnail/1.jpg" alt=""/>
            </a>
            <a class="thumbnail-line-entry overlay-thumbnail">
                <img src="../assets/image/thumbnail/2.jpg" alt=""/>
                <img src="../assets/image/thumbnail/2.jpg" alt=""/>
            </a>
            <a class="thumbnail-line-entry overlay-thumbnail">
                <img src="../assets/image/thumbnail/3.jpg" alt=""/>
                <img src="../assets/image/thumbnail/3.jpg" alt=""/>
            </a>
            <a class="thumbnail-line-entry overlay-thumbnail">
                <img src="../assets/image/thumbnail/4.jpg" alt=""/>
                <img src="../assets/image/thumbnail/4.jpg" alt=""/>
            </a>
            <a class="thumbnail-line-entry overlay-thumbnail">
                <img src="../assets/image/thumbnail/5.jpg" alt=""/>
                <img src="../assets/image/thumbnail/5.jpg" alt=""/>
            </a>
            <a class="thumbnail-line-entry overlay-thumbnail">
                <img src="../assets/image/thumbnail/6.jpg" alt=""/>
                <img src="../assets/image/thumbnail/6.jpg" alt=""/>
            </a>
            <a class="thumbnail-line-entry overlay-thumbnail">
                <img src="../assets/image/thumbnail/7.jpg" alt=""/>
                <img src="../assets/image/thumbnail/7.jpg" alt=""/>
            </a>
            <a class="thumbnail-line-entry overlay-thumbnail">
                <img src="../assets/image/thumbnail/8.jpg" alt=""/>
                <img src="../assets/image/thumbnail/8.jpg" alt=""/>
            </a>
            <a class="thumbnail-line-entry overlay-thumbnail">
                <img src="../assets/image/thumbnail/9.jpg" alt=""/>
                <img src="../assets/image/thumbnail/9.jpg" alt=""/>
            </a>
            <a class="thumbnail-line-entry overlay-thumbnail">
                <img src="../assets/image/thumbnail/10.jpg" alt=""/>
                <img src="../assets/image/thumbnail/10.jpg" alt=""/>
            </a>
            <a class="thumbnail-line-entry overlay-thumbnail">
                <img src="../assets/image/thumbnail/11.jpg" alt=""/>
                <img src="../assets/image/thumbnail/11.jpg" alt=""/>
            </a>
            <a class="thumbnail-line-entry overlay-thumbnail">
                <img src="../assets/image/thumbnail/12.jpg" alt=""/>
                <img src="../assets/image/thumbnail/12.jpg" alt=""/>
            </a>
            <a class="thumbnail-line-entry overlay-thumbnail">
                <img src="../assets/image/thumbnail/13.jpg" alt=""/>
                <img src="../assets/image/thumbnail/13.jpg" alt=""/>
            </a>
            <a class="thumbnail-line-entry overlay-thumbnail">
                <img src="../assets/image/thumbnail/14.jpg" alt=""/>
                <img src="../assets/image/thumbnail/14.jpg" alt=""/>
            </a>
            <a class="thumbnail-line-entry overlay-thumbnail">
                <img src="../assets/image/thumbnail/15.jpg" alt=""/>
                <img src="../assets/image/thumbnail/15.jpg" alt=""/>
            </a>
            <a class="thumbnail-line-entry overlay-thumbnail">
                <img src="../assets/image/thumbnail/16.jpg" alt=""/>
                <img src="../assets/image/thumbnail/16.jpg" alt=""/>
            </a>
            <a class="thumbnail-line-entry overlay-thumbnail">
                <img src="../assets/image/thumbnail/17.jpg" alt=""/>
                <img src="../assets/image/thumbnail/17.jpg" alt=""/>
            </a>
            <a class="thumbnail-line-entry overlay-thumbnail">
                <img src="../assets/image/thumbnail/18.jpg" alt=""/>
                <img src="../assets/image/thumbnail/18.jpg" alt=""/>
            </a>
            <a class="thumbnail-line-entry overlay-thumbnail">
                <img src="../assets/image/thumbnail/19.jpg" alt=""/>
                <img src="../assets/image/thumbnail/19.jpg" alt=""/>
            </a>
            <a class="thumbnail-line-entry overlay-thumbnail">
                <img src="../assets/image/thumbnail/20.jpg" alt=""/>
                <img src="../assets/image/thumbnail/20.jpg" alt=""/>
            </a>
        </div>

        <div class="container">
            <div class="empty-space col-b55 col-md-b110"></div>

            <div class="row justify-content-center">
                <div class="col-md-6 text-center">
                    <div class="h2 small text-uppercase"><b>Brands Worked On</b></div>
                    <div class="empty-space col-b15"></div>
                    <div class="simple-article large grey d-none">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                        do eiusmod tempor incididunt ut labore et dolore magna aliqua
                    </div>
                </div>
            </div>

            <div class="empty-space col-b30 col-md-b60"></div>

            <div class="row nopadding">
                <div class="col-6 col-md-2 client-entry-wrapper">
                    <a href="#" class="client-entry">
                        <img class="client-logo" src="../assets/image/client/01.jpg" alt=""/>
                        <span class="client-thumbnail"><img src="../assets/image/client/01.jpg" alt=""/></span>
                    </a>
                </div>
                <div class="col-6 col-md-2 client-entry-wrapper">
                    <a href="#" class="client-entry">
                        <img class="client-logo" src="../assets/image/client/02.jpg" alt=""/>
                        <span class="client-thumbnail"><img src="../assets/image/client/02.jpg" alt=""/></span>
                    </a>
                </div>
                <div class="col-6 col-md-2 client-entry-wrapper">
                    <a href="#" class="client-entry">
                        <img class="client-logo" src="../assets/image/client/03.jpg" alt=""/>
                        <span class="client-thumbnail"><img src="../assets/image/client/03.jpg" alt=""/></span>
                    </a>
                </div>
                <div class="col-6 col-md-2 client-entry-wrapper">
                    <a href="#" class="client-entry">
                        <img class="client-logo" src="../assets/image/client/04.jpg" alt=""/>
                        <span class="client-thumbnail"><img src="../assets/image/client/04.jpg" alt=""/></span>
                    </a>
                </div>
                <div class="col-6 col-md-2 client-entry-wrapper">
                    <a href="#" class="client-entry">
                        <img class="client-logo" src="../assets/image/client/05.jpg" alt=""/>
                        <span class="client-thumbnail"><img src="../assets/image/client/05.jpg" alt=""/></span>
                    </a>
                </div>
                <div class="col-6 col-md-2 client-entry-wrapper">
                    <a href="#" class="client-entry">
                        <img class="client-logo" src="../assets/image/client/06.jpg" alt=""/>
                        <span class="client-thumbnail"><img src="../assets/image/client/06.jpg" alt=""/></span>
                    </a>
                </div>
                <div class="col-6 col-md-2 client-entry-wrapper">
                    <a href="#" class="client-entry">
                        <img class="client-logo" src="../assets/image/client/07.jpg" alt=""/>
                        <span class="client-thumbnail"><img src="../assets/image/client/07.jpg" alt=""/></span>
                    </a>
                </div>
                <div class="col-6 col-md-2 client-entry-wrapper">
                    <a href="#" class="client-entry">
                        <img class="client-logo" src="../assets/image/client/08.jpg" alt=""/>
                        <span class="client-thumbnail"><img src="../assets/image/client/08.jpg" alt=""/></span>
                    </a>
                </div>
                <div class="col-6 col-md-2 client-entry-wrapper">
                    <a href="#" class="client-entry">
                        <img class="client-logo" src="../assets/image/client/09.jpg" alt=""/>
                        <span class="client-thumbnail"><img src="../assets/image/client/09.jpg" alt=""/></span>
                    </a>
                </div>
                <div class="col-6 col-md-2 client-entry-wrapper">
                    <a href="#" class="client-entry">
                        <img class="client-logo" src="../assets/image/client/10.jpg" alt=""/>
                        <span class="client-thumbnail"><img src="../assets/image/client/10.jpg" alt=""/></span>
                    </a>
                </div>
            </div>

            <div class="empty-space col-b40 col-md-b50"></div>

        </div>

    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';

export default {
    metaInfo: {
        title: 'About'
    },
    components: {
        Swiper,
        SwiperSlide
    },
    data () {
        return {
            window: {
                height: window.innerHeight
            },
            swiperOption1: {
                centeredSlides: true,
                effect: 'fade',
                fadeEffect: { crossFade: true },
                speed: 3000,
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false
                },
                slidesPerView: 1,
                spaceBetween: 0,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                loop: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                breakpoints: {
                    1024: {
                        slidesPerView: 1,
                        spaceBetween: 0
                    },
                    768: {
                        slidesPerView: 1,
                        spaceBetween: 0
                    },
                    640: {
                        slidesPerView: 1,
                        spaceBetween: 0
                    },
                    320: {
                        slidesPerView: 1,
                        spaceBetween: 0
                    }
                }
            }
        };
    }
};
</script>

<style scoped>

.quotes{
    line-height: 2rem !important;
    font-size: 1.2rem;
    padding: 0 1rem;
    color: white;
}

@media (min-width: 992px) {

    .quotes{
        line-height: 3rem !important;
        font-size: 1.75rem;
        padding: 0 10rem;
        color: white;
    }

}

</style>
