<template>
    <div id="content-block">

        <div class="contacts-wrapper-1" style="min-height:100vh;">
            <div class="container">
                <div class="header-empty-space"></div>
                <div class="empty-space col-b25 col-md-b50"></div>
                <div class="empty-space col-b25 col-md-b50"></div>

                <div class="row justify-content-lg-center">
                    <div class="col-lg-6 text-center">
                        <div class="h2 light small"><b>CONTACT US</b></div>
                        <div class="empty-space col-b15"></div>
                        <div class="simple-article large light transparent text-center d-none">Lorem ipsum dolor sit amet,
                            consectetur adipiscing elit. Integer semper elit nec justo auctor lacinia. Curabitur
                            eleifendegesta
                        </div>
                    </div>
                </div>

                <div class="empty-space col-b30 col-md-b60"></div>

                <div class="row">
                    <div class="col-md-3">
                        <div class="contacts-entry">
                            <div class="h4 light small"><b>ADDRESS</b></div>
                            <div class="empty-space col-b15"></div>
                            <div class="simple-article light transparent">
                                Studio 1988 <br/>
                                Thycaud, Trivandrum 14
                            </div>
                        </div>
                        <div class="empty-space col-b25 col-md-b0"></div>
                    </div>
                    <div class="col-md-3">
                        <div class="contacts-entry">
                            <div class="h4 light small"><b>PHONE</b></div>
                            <div class="empty-space col-b15"></div>
                            <div class="simple-article light transparent">
                                <a class="mouseover-simple" href="tel:+8891113007">+91 8891 113 007</a><br/>
                            </div>
                        </div>
                        <div class="empty-space col-b25 col-md-b0"></div>
                    </div>
                    <div class="col-md-3">
                        <div class="contacts-entry">
                            <div class="h4 light small"><b>EMAIL</b></div>
                            <div class="empty-space col-b15"></div>
                            <div class="simple-article light transparent">
                                <a class="mouseover-simple" target="_blank" href="mailto:contacttsvishnu@gmail.com">contacttsvishnu@gmail.com</a><br/>
                            </div>
                        </div>
                        <div class="empty-space col-b25 col-md-b0"></div>
                    </div>
                    <div class="col-md-3">
                        <div class="contacts-entry">
                            <div class="h4 light small"><b>FOLLOW US</b></div>
                            <div class="empty-space col-b15"></div>
                            <div class="follow">
                                <a href="https://imdb.com/name/nm7837206/?ref_=nmbio_bio_nm" class="entry" target="_blank"><i class="fa fa-imdb"></i></a>
                                <a href="https://www.facebook.com/TSVishnumusic/" class="entry" target="_blank"><i class="fa fa-facebook"></i></a>
                                <a href="https://www.instagram.com/tsvishnu/" class="entry" target="_blank"><i class="fa fa-instagram"></i></a>
                                <a href="https://youtube.com/channel/UCIJEmBBs_M3ALluDiGgyH4A" class="entry" target="_blank"><i class="fa fa-youtube-play"></i></a>
                                <a href="https://twitter.com/VishnuTS?s=09" class="entry" target="_blank"><i class="fa fa-twitter"></i></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="empty-space col-b55 col-md-b110 d-none"></div>

                <div class="row justify-content-lg-center d-none">
                    <div class="col-lg-8 text-center">
                        <div class="h4 light"><b>HAVE A QUESTION?</b></div>
                    </div>
                </div>

                <div class="empty-space col-b90 col-md-b120"></div>
            </div>
        </div>

        <div class="container d-none">
            <div class="contacts-form-1-align">
                <div class="row justify-content-center">
                    <div class="col-10">
                        <div class="empty-space col-b60"></div>

                        <transition name="fade" mode="out-in">
                            <div class="alert alert-success" v-if="state === 'S'" key="'S">
                                <div class="d-flex justify-content-center align-items-center">
                                    <div class="text-center p-4">
                                        <!--                                        <img src="../assets/img/positive-vote.svg" class="img-fluid w-50 my-4" alt="#">-->
                                        <p class="c-font-19 mb-4 proxim-light"> The mail has been successfully
                                            submitted.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="alert alert-danger" v-else-if="state === 'F'" key="'F">
                                <div class="d-flex justify-content-center align-items-center">
                                    <div class="text-center p-4">
                                        <!--                                        <img src="../assets/img/error.svg" class="img-fluid w-50 my-4" alt="#">-->
                                        <p class="c-font-19 mb-4 proxim-light">The mail has been failed.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="alert alert-info" v-else-if="state === 'L'" key="'L">
                                <div class="d-flex justify-content-center align-items-center">
                                    <div class="text-center p-4">
                                        <loading-animation/>
                                        <p class="c-font-19 mb-4 proxim-light">Please wait while communicate with the
                                            server</p>
                                    </div>
                                </div>
                            </div>
                            <form class="contact-form" role="form" v-else @submit.prevent="onSubmit">
                                <div class="row col-b30">
                                    <div class="col-md-6 col-b30 col-md-b0">
                                        <div class="input-wrapper label-animate">
                                            <input name="name" placeholder=" " class="input" type="text" v-model="name"
                                                   required autocomplete="off">
                                            <label>Name</label>
                                            <span></span>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="input-wrapper label-animate">
                                            <input name="email" placeholder=" " class="input" type="email"
                                                   v-model="email" required autocomplete="off">
                                            <label>Email</label>
                                            <span></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row col-b30">
                                    <div class="col-md-12">
                                        <div class="input-wrapper label-animate">
                                            <input name="subject" placeholder=" " class="input" type="text"
                                                   v-model="subject" required autocomplete="off">
                                            <label>Subject</label>
                                            <span></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="input-wrapper label-animate">
                                            <textarea name="message" placeholder=" " class="input" v-model="message"
                                                      required></textarea>
                                            <label>Message</label>
                                            <span></span>
                                        </div>
                                    </div>
                                </div>

                                <div class="empty-space col-b40"></div>

                                <div class="text-center">
                                    <button class="button type-3 btn" type="submit">
                                        submit message
                                    </button>
                                </div>
                            </form>
                        </transition>

                        <div class="empty-space col-b60"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="map-wrapper d-none">
            <div id="map-canvas" class="full-width" data-lat="34.0151244" data-lng="-118.4729871" data-zoom="14"></div>
        </div>
        <div class="addresses-block hidden">
            <a class="marker" data-lat="34.0151244" data-lng="-118.4729871"
               data-string="1. Here is some address or email or phone or something else..."></a>
        </div>

    </div>
</template>

<script>
import axios from 'axios';
import LoadingAnimation from '../components/LoadingAnimation';

export default {
    name: 'Contact',
    metaInfo: {
        title: 'Contact'
    },
    components: { LoadingAnimation },
    data () {
        return {
            state: 'D',
            name: '',
            email: '',
            subject: '',
            message: ''
        };
    },
    methods: {
        onSubmit () {
            const bodyFormData = new FormData();
            bodyFormData.append('name', this.name);
            bodyFormData.append('email', this.email);
            bodyFormData.append('subject', this.subject);
            bodyFormData.append('message', this.message);

            const that = this;
            this.state = 'L';

            axios({
                method: 'post',
                url: 'https://musicians.xeoscript.com/mail.php',
                data: bodyFormData,
                headers: { 'Content-Type': 'multipart/form-data' }
            }).then(function (response) {
                that.onSubmitSuccess(response);
            }).catch(function (response) {
                that.onSubmitFailed(response);
            });
        },

        onSubmitSuccess () {
            this.state = 'S';
        },

        onSubmitFailed () {
            this.state = 'F';
        }
    }
};
</script>

<style scoped>

</style>
